import React, { useEffect, useRef, useState } from "react";
import styles from "../penguins.module.scss";
import Slider from "../../General/swiper/swiper";
import { Grid, Container } from "@material-ui/core";
import useLocale from "../../../lang/useLocale";

export default function MeetTrainers(props) {
  const { getLocale } = useLocale();
  // const { data } = props.data;
  const [firstTraniers, setFirstTrainers] = useState([]);
  const [secondTraniers, setSecondTrainers] = useState([]);
  const [allTraniers, setAllTrainers] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.data.plugin)) {
      setFirstTrainers(
        props.data.plugin.find((trainer) => {
          return trainer.id === 1;
        })
      );
      setSecondTrainers(
        props.data.plugin.find((trainer) => {
          return trainer.id === 2;
        })
      );
      setAllTrainers(
        props.data.plugin.filter((trainer) => {
          if (trainer.id !== 2 && trainer.id !== 1) {
            return trainer;
          }
          return null;
        })
      );
    }
  }, [props.data]);
  // console.log(allTraniers);
  const mySwiper1 = useRef();
  const mySwiper2 = useRef();
  const settings1 = {
    arrows: true,
    bullets: false,
    // loop: false,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 2,
      },
    },
  };
  const settings2 = {
    arrows: false,
    bullets: true,
    loop: false,
    spaceBetween: 10,
    autoplay: {
      delay: 10000,
    },
    autoplayDisableOnInteraction: true,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 4,
      },
    },
  };
  return (
    <div className={styles.meetTrainersWrap}>
      <Container>
        <div
          className={`${styles.ticketsPenguins} penguins ${styles.trainers}`}
        >
          <div className={styles.meet}>
            <h4>{getLocale("MEET THE PENGUINS TEAM")}</h4>
          </div>
          <div id="swiperPenguin">
            <Container maxWidth="md" className={styles.teamContainer}>
              <Slider settings={settings1} mySwiper={mySwiper1}>
                <div
                  className={`swiper-slide ${styles.penguinSlide} ${styles.foreign}`}
                >
                  <img src={firstTraniers.image} alt="penguin" />
                  <div className={styles.wrap}>
                    <h5>{firstTraniers.name}</h5>
                    <Grid container justify="space-between">
                      <Grid item xs={12} className={styles.sliderItem}>
                        <span>{firstTraniers.title}</span>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div
                  className={`swiper-slide ${styles.penguinSlide} ${styles.foreign}`}
                >
                  <img src={secondTraniers.image} alt="penguin" />
                  <div className={styles.wrap}>
                    <h5>{secondTraniers.name}</h5>
                    <Grid container justify="space-between">
                      <Grid item xs={12} className={styles.sliderItem}>
                        <span>{secondTraniers.title}</span>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Slider>
            </Container>
            <Slider settings={settings2} mySwiper={mySwiper2}>
              {allTraniers.map((trainer) => {
                return (
                  <div
                    key={trainer.id}
                    className={`swiper-slide ${styles.penguinSlide} ${styles.egyptian}`}
                  >
                    <img src={trainer.image} alt="penguin" />
                    <h5>{trainer.name}</h5>
                    <Grid container justify="space-between">
                      <Grid item xs={12} className={styles.sliderItem}>
                        <span>{trainer.title}</span>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  );
}
