import React from "react";
import styles from "./summerCamp.module.scss";
import summerCampEn from "../../img/summer-camp-en.png";
import summerCampAr from "../../img/summer-camp-ar.png";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import HeaderPanel from "../General/headerPanel/headerPanel";
import { Box, Container, Grid } from "@material-ui/core";
import useLocale from "../../lang/useLocale";
export default function SummerCamp() {
  const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();

  const data = [{
    id: 1,
    name: getLocale("Daily Summer Camp"),
    price: 750,
    description: getLocale("Daily Summer description"),
    book: lang === "en" ? "https://booking.skiegy.com/en/snow-park/booking/daily-summer-camp" : "https://booking.skiegy.com/ar/snow-park/booking/daily-summer-camp"
  }, {
    id: 2,
    name: getLocale("Weekly Summer Camp"),
    price: 2500,
    description: getLocale("Weekly Summer description"),

    book: lang === "en" ? "https://booking.skiegy.com/en/snow-park/booking/weekly-summer-camp" : "https://booking.skiegy.com/ar/snow-park/booking/weekly-summer-camp"
  },
  {
    id: 1,
    name: getLocale("Monthly Summer Camp"),
    price: 6000,
    description: getLocale("Monthly Summer description"),
    book: lang === "en" ? "https://booking.skiegy.com/en/snow-park/booking/monthly-summer-camp" : "https://booking.skiegy.com/ar/snow-park/booking/monthly-summer-camp"

  }]

  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href={`https://skiegy.com/${lang}/summer-camp`}
        />
      </Helmet>
      <HeaderPanel
        panal={lang === "en" ? summerCampEn : summerCampAr}
        hideSnow={true}
      />
      <Box className={styles.bgContainer}>
        <Container className={styles.infoContainer}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={10}>
              <h1>{getLocale("SUMMER CAMP")}</h1>
              <p>{getLocale("Ski Egypt's Summer Camp")}</p>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box>
        <div
          className={`${styles.ticketsPenguins} ${styles.tickets} ${styles.ticketsWrap} penguins`}
        >
          <Container>
            <div className={styles.ticketsTitles}>
              <h4>{getLocale("Summer Camp packages")}</h4>
            </div>
            <Grid
              container
              spacing={1}
              className={styles.cardsWrap}
              justifyContent="center"
            >
              {
                data.map((packageItem) => {
                  return (
                    <Grid
                      key={packageItem.id}
                      item
                      xs={12}
                      sm={4}
                      className={styles.card}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        direction="column"
                        className="cardHeight"
                      >
                        <Grid item>
                          <h5 className={`${styles.title} ${styles.titleCard}`}>
                            {packageItem.name}
                          </h5>
                          <h4 className={styles.price}>
                            <span>{packageItem.price}</span>
                            <span
                              className={styles.egp}
                              style={{ margin: "0 5px" }}
                            >
                              /{getLocale("LE")}
                            </span>
                          </h4>
                          <div className={styles.description}>
                            {packageItem.description}
                          </div>
                        </Grid>
                        <Grid item>
                          <a
                            href={packageItem.book}
                            target="_self"
                            rel="noopener noreferrer"
                            className={styles.book}
                          >
                            {getLocale("BOOK NOW")}
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}

            </Grid>
          </Container>
        </div>
      </Box>
    </div>
  );
}
