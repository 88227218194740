import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import styles from "../penguins.module.scss";
import Slider from "../../General/swiper/swiper";
import { Grid, Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import type from "../../../img/type.svg";
import gender from "../../../img/gender.svg";
// import twitterSultan from "../../../img/twitterSultan.svg";
import instSultan from "../../../img/instSultan.svg";
import useLocale from "../../../lang/useLocale";
import { useSelector } from "react-redux";

const MeetPenguins = (props) => {
  const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();
  // const { data } = props.data;
  const [sultan, setSultan] = useState([]);
  const [allPenguins, setAllPenguins] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.data?.plugin)) {
      setSultan(
        props.data.plugin?.find((trainer) => {
          return trainer.id === 1;
        })
      );

      setAllPenguins(
        props.data.plugin?.filter((trainer) => {
          if (trainer.id !== 1) {
            return trainer;
          }
          return null;
        })
      );
    }
  }, [props.data]);
  // console.log(allPenguins, sultan);
  const mySwiper = useRef();
  const settings = {
    arrows: false,
    bullets: true,
    // loop: true,
    spaceBetween: 20,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 4,
      },
    },
  };
  const meetPenguinsRef = useRef(null);

  useLayoutEffect(() => {
    if (
      props.location?.state?.moveTo === "meetPenguins" &&
      meetPenguinsRef.current
    ) {
      setTimeout(() => {
        meetPenguinsRef.current.scrollIntoView();
      }, 1000);
    }
  }, [meetPenguinsRef, props.location]);

  return (
    <div className={styles.penguinWrap}>
      <Container>
        <div
          className={`${styles.ticketsPenguins} penguins`}
          ref={meetPenguinsRef}
          id="meet"
        >
          <div className={styles.meet}>
            <h4>{getLocale("meetpenguins")}</h4>
          </div>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={styles.sultanWrapper}
          >
            <Grid item sm={12} md={12}>
              {/* <img src={sultanStar} alt='penguin'/> */}
              <Grid
                container
                justify="space-between"
                alignItems="center"
                direction={lang === "en" ? "row" : "row-reverse"}
              >
                <Grid item xs={12} md={6}>
                  <h5 className={styles.sultan}>
                    {getLocale("'SULTAN' OUR STAR")}
                  </h5>
                  <p className={styles.sultanexert}>{sultan.description}</p>
                  <div className={styles.follow}>
                    {getLocale("follow Sultan on")}
                    <span>
                      {/* <a href='https://mobile.twitter.com/sultanelpenguin' target='_blank' alt='social' rel="noopener noreferrer">
                                                <img src={twitterSultan} alt='img'/>
                                            </a> */}
                      <a
                        href={sultan.insta_url}
                        target="_blank"
                        alt="social"
                        rel="noopener noreferrer"
                      >
                        <img src={instSultan} alt="img" />
                      </a>
                    </span>
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  justify={lang === "en" ? "flex-end" : "flex-start"}
                  xs={12}
                  md={6}
                  alignItems="center"
                  className={styles.sultanIconsWrap}
                >
                  <Grid item xs={12}>
                    <img src={sultan.image} alt="img" />
                  </Grid>
                  <Grid item xs={12} container direction="row" justify="center">
                    <Grid item xs={3} className={styles.sultanPeng}>
                      <img src={type} alt="img" /> <span>{sultan.type}</span>
                    </Grid>
                    <Grid item xs={3} className={styles.sultanPeng}>
                      <img src={gender} alt="img" />{" "}
                      <span>{sultan.gender}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div id="swiperPenguin" className={styles.meetSection}>
            <Slider settings={settings} mySwiper={mySwiper}>
              {allPenguins.map((penguin) => {
                return (
                  <div
                    key={penguin.id}
                    className={`swiper-slide ${styles.penguinSlide}`}
                  >
                    <img src={penguin.image} alt="ski penguins" />
                    <h5>{penguin.name}</h5>
                    <Grid
                      container
                      item
                      justify="space-between"
                      xs={12}
                      alignItems="center"
                    >
                      <Grid item xs={6} className={styles.sliderItem}>
                        <img src={type} alt="img" /> <span>{penguin.type}</span>
                      </Grid>
                      <Grid item xs={6} className={styles.sliderItem}>
                        <img src={gender} alt="img" />{" "}
                        <span>{penguin.gender}</span>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(MeetPenguins);
