import axios from "axios";
import { store } from "../../index";
import {
  ACTIVITY_LIST_FAIL,
  ACTIVITY_LIST_REQUEST,
  ACTIVITY_LIST_SUCCESS,
  LIST_OPTIONS_FAIL,
  LIST_OPTIONS_REQUEST,
  LIST_OPTIONS_SUCCESS,
  FILTERED_DATA_FAIL,
  FILTERED_DATA_REQUEST,
  FILTERED_DATA_SUCCESS,
} from "../constants/skiSchoolConstants";

export const activityListActions = () => async (dispatch) => {
  dispatch({
    type: ACTIVITY_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/quiz`, {
      headers: {
        "Content-Language": `${store.getState().lang}`,
      },
    });
    dispatch({ type: ACTIVITY_LIST_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: ACTIVITY_LIST_FAIL, payload: error.message });
  }
};
export const listOptionsActions = (activity) => async (dispatch) => {
  dispatch({
    type: LIST_OPTIONS_REQUEST,
  });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/quiz?find[activity]=${activity}`,
      {
        headers: {
          "Content-Language": `${store.getState().lang}`,
        },
      }
    );
    dispatch({ type: LIST_OPTIONS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: LIST_OPTIONS_FAIL, payload: error.message });
  }
};

export const filteredDataActions =
  (activity, age, level, privacy) => async (dispatch) => {
    dispatch({
      type: FILTERED_DATA_REQUEST,
    });
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/lessons?find[activity]=${activity}&find[level]=${level}&find[age]=${age}&find[group]=${privacy}`,
        {
          headers: {
            "Content-Language": `${store.getState().lang}`,
          },
        }
      );
      dispatch({ type: FILTERED_DATA_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({ type: FILTERED_DATA_FAIL, payload: error.message });
    }
  };
