import { useSelector } from 'react-redux'
import en from './en.json'
import ar from './ar.json'

// Please note in json files everything must be a string and no comments are allowed

const translations = {
    en,
    ar
}

// A custom hook for translating

function useLocale() {
    //this is a new hook to get the store values in redux
    //here i am getting (lang) value from the store
    const lang = useSelector(state => state.lang)
    //you can get multiple values as an example in the next commented line
    //const {lang, data1, data2} = useSelector(state => {lang: state.lang, data1: state.data1, data2: state.data2})
    function getLocale(string = '') {
        return translations[lang][string] ?? string
    }

    return { getLocale }
}

export default useLocale