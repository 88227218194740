import React, { useRef } from "react";
import styles from "./Slider.module.scss";
import { Container, Grid } from "@material-ui/core";
import SwiperSlider from "../../../General/swiper/swiper";
// import * as imgs from "./images";
import { useSelector } from "react-redux";
// import useLocale from "../../../../lang/useLocale";
// import { useDispatch } from "react-redux";
// import { activitiesActions } from "../../../../redux/actions/aboutActions";
export default function Slider(props) {
  // console.log(props.data);
  const lang = useSelector((state) => state.lang);
  // const activities = useSelector((state) => state.activityReducer);
  // const { data } = activities;
  // const dispatch = useDispatch();
  // console.log(loading,data);
  // const { getLocale } = useLocale();
  const mySwiper = useRef();
  const settings = {
    arrows: true,
    loop: false,
    spaceBetween: 10,
    autoplay: {
      delay: 10000,
    },
    autoplayDisableOnInteraction: true,
    slidesPerView: 1,
    bullets: true,
    thumbs: true,
  };
  // useEffect(() => {
  //   if (lang) {
  //     dispatch(activitiesActions());
  //   }
  // }, [dispatch, lang]);
  return (
    <div className={`${styles.ContainerBackground}`}>
      <Container maxWidth="lg" className="aboutSlider">
        <SwiperSlider settings={settings} mySwiper={mySwiper}>
          {props.data?.plugin?.map((activity, index) => {
            return (
              <div
                key={index}
                className={`${styles.packageSlide} swiper-slide`}
              >
                <Grid
                  container
                  direction={lang === "en" ? "row" : "row-reverse"}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    className={`${styles.slideData}`}
                  >
                    <Grid item xs={12}>
                      <p className={`${styles.subTitle}`}>{props.data.title}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className={`${styles.Title}`}>{activity.title}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className={`${styles.Description}`}>
                        {activity.description}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    className={`${styles.slideImg}`}
                  >
                    <img src={activity.image} alt="img" />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </SwiperSlider>
      </Container>
    </div>
  );
}
