import { FETCH_DATA_FB } from '../actions/shared'
const initiaValue = { data: [] }

export default function fb(state = initiaValue , action) {
    switch (action.type) {
        case FETCH_DATA_FB:
            return { data: [...state.data, ...action.data.data], next: action.data.paging.next }
        default:
            return state
    }
}