import React from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "./professionalPackages.module.scss";
// import packagesImg1 from "../../../img/freestyle-pass-package.png";
// import useLocale from "../../../lang/useLocale";
import { Registration } from "./registration/registeration";
function FreeStylePackage(props) {
  // const { getLocale } = useLocale();
  // console.log(props.data);
  return (
    <Container maxWidth={false} className={styles.professionalPackages}>
      <Grid
        container
        justify="center"
        className={styles.containerGridAll}
        direction="row-reverse"
      >
        {props.data.plugin?.map((plug) => {
          return (
            <React.Fragment key={plug.id}>
              <Grid
                item
                xs={12}
                md={6}
                xl={5}
                className={`${styles.containerImgRight}`}
              >
                <img src={plug.image} alt="img" className={styles.right} />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={5}
                className={`${styles.containerData} ${styles.left} `}
              >
                <Grid item xs={12} className={`${styles.Header}`}>
                  <p className={styles.blue}>{plug.title}</p>
                </Grid>
                <Grid item xs={12} className={`${styles.subTitle}`}>
                  <p>{plug.subtitle}</p>
                </Grid>
                <Grid item xs={12} className={`${styles.description}`}>
                  <p className={styles.black}>{plug.description}</p>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.PackagePerPerson}`}
                  alignItems="center"
                >
                  <Grid item xs={12} md={6} className={`${styles.Header}`}>
                    <Registration path={"profissionals"} formTitle={"freestyle night"} formName={"Freestyle Night"} />
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Container>
  );
}
export default FreeStylePackage;
