import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import styles from "./professionals.module.scss";
import VideoBasic from "../penguins/video/video";
// import school from "../../img/skiSchoolnew.jpg";
import SocialSection from "../General/social-section/social-section";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions';
// import HeaderText from "../offers/subOffers/HeaderText/HeaderText";
// import videoImg from "../../img/professionals.jpg";
// import useLocale from "../../lang/useLocale";
import SlopePassPackage from "./professionalPackages/slopePassPackage";
import FreeStylePackage from "./professionalPackages/freeStylePackage";
import CrystalPackages from "./crystalPackages/crystalPackages";
import { Grid, Container } from "@material-ui/core";
// import PackageCard from "../General/packageCard/packageCard";
import bg from "../../img/professionalsBg.jpg";
// import packagesImg2 from "../../img/slope-pass-packages.jpg";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
// import RamadanCamp from "./RamadanCamp";

export default function Professionals() {
  // const { getLocale } = useLocale();

  const dataProfessionals = useSelector((state) => state.webReducer);
  const [profData, setProfData] = useState([]);
  const { data } = dataProfessionals;
  // console.log(profData);
  useEffect(() => {
    if (Array.isArray(data)) {
      setProfData(data[2]);
    }
  }, [data, profData]);
  const lang = useSelector((state) => state.lang);
  return (
    <div className={styles.professionals}>
      <Helmet>
        <link
          rel="canonical"
          href={`https://skiegy.com/${lang}/professionals`}
        />
      </Helmet>
      <VideoBasic
        title="PROFESSIONALS' JOURNEY"
        img={profData.image}
        videoUrl={profData.video}
      />
      {/* <RamadanCamp /> */}
      <CrystalPackages />
      <Container className={styles.professionalPackages}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            {/* <h3>{profData.title}</h3> */}
            <h2>{profData.subtitle}</h2>
            <p>{profData.description}</p>
          </Grid>
        </Grid>
      </Container>
      {/* <HeaderText
          title={getLocale("Expert Packages")}
          subtitle={getLocale("Professionals")}
          description={getLocale("professionalContent")}
        /> */}

      <SlopePassPackage
        data={Array.isArray(profData.sections) && profData.sections[0]}
      />
      {/* <Grid container spacing={1} justify="center">
			<Grid item md={6} xs={12}>
			<PackageCard img={packagesImg2} title={getLocale("Slope Pass Package")} paragraph={getLocale("Slope Pass Package Content")} price="300" contact={getLocale("BOOK NOW")} link={`https://booking.skiegy.com/${lang}/slope/booking/full-day-pass`}/>
			</Grid>
			<Grid item md={6} xs={12}>
			<PackageCard img={packagesImg1} title={getLocale("Freestyle Pass Package")} paragraph={getLocale("Freestyle Pass Package Content")} price="300" contact={getLocale("CALL US")} link="tel:16002"/>
			</Grid></Grid> */}
      <img src={bg} alt="professionals" />
      <p className={styles.start}>
        {Array.isArray(profData.sections) && profData.sections[1].title}
      </p>
      <p className={styles.date}>
        {Array.isArray(profData.sections) && profData.sections[1].subtitle}
      </p>
      <FreeStylePackage
        data={Array.isArray(profData.sections) && profData.sections[1]}
      />

      {/* <div className={styles.bgColor}>
        <p>{getLocale("COMING SOON")}</p>
        <p>{getLocale("wait for our new Slope memberships")}</p>
      </div> */}
      {/* <CrystalPackages /> */}
      {/* </div> */}
      <SocialSection />
      {/* <OurAttractions /> */}
    </div>
  );
}
