import { CHANGE_LANG } from './shared'

function language(lang) {
    return {
        type: CHANGE_LANG,
        lang
    }
}

export function handleLang(lang) {
    return dispatch => {
        document.documentElement.lang = lang
        dispatch(language(lang))
    }
}