import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function SliderSwiper(props) {

	return (
		<Slider {...props.settings}>
			{props.children}
		</Slider>
	);
}
