import React, { useRef } from "react";
import SocialSection from "../General/social-section/social-section";
import { SkiClubProgram } from "../skiSchool/subComponents/skiClubProgram/skiClubProgram";
import backgroundSki from "../../img/ski-club-program-bg.jpg";
import useLocale from "../../lang/useLocale";

const SkiClubProgramData = {
    button: 'Register Now',
    description: `<p>We're excited to announce that this Friday - the 13th of January - Ski Egypt will be participating in a worldwide competition: "FIS Snow World Day,” this event will be happening across 135 Ski resorts. </br></br>The event will take place on Friday from 9:00 AM to 1:00 PM. Participants who will join the event will all receive a certificate for participating in the World’s Snow Day.</br></br>This exciting day also promises a ton of memorable activities including: skiing & snowboarding lessons, a penguin encounter, a snow man challenge, musical entertainment and much more!</br></br></p>`,
    subtitle: "INTRODUCING",
    registration: "On January 13th",
    title: "WORLD SNOW DAY IN SKI EGYPT",
    // note: "*Participant needs to have completed a discovery lesson prior to enrollment",
}
const SkiClubProgramDataAR = {
    button: 'إنضم الآن',
    description: `<p>يسعدنا اننا نعلن إن سكي مصر لأول مرة هتشارك في المسابقة العالمية "FIS Snow World Day"</br></br>يوم الجمعة اللي جاية  13 يناير وده واحد من أهم الإيفينتات اللي بتحصل سنوياً في أكتر من 135 منتجع تزلج حول العالم .</br></br>الإيفينت هيكون يوم الجمعة من الساعة 9:00 صباحاً لحد 1:00 ظهراً واللي هيشاركوا اليوم ده هيقدروا يحصلوا على شهادة للمشاركة في يوم الثلج العالمي.</br></br>وكل ده غير الأنشطة الكتير اللي هتكون موجودة يومها زي: دروس التزلج والتزحلق على الجليد، مقابلة البطاريق، تحدي رجل الثلج، الترفيه والموسيقى</br></br></p>`,
    registration: "في 13 يناير",
    subtitle: "نقدم لكم",
    title: "يوم الثلج العالمي",
    // note: "يشترط إكمال الدرس الإستكشافي (Discovery) قبل التسجيل",
}

function SkiSchoolLanding() {
    const { getLocale } = useLocale();
    // const [clicked, setClicked] = useState(false);
    const schoolRef = useRef();

    const chooseYourLesson = () => {
        // setClicked(true);
        schoolRef.current.getBoundingClientRect();
    };
    return (
        <div>
            <SkiClubProgram
                animation={false}
                background={backgroundSki}
                data={getLocale("dir") === "rtl" ? SkiClubProgramDataAR : SkiClubProgramData}
                click={chooseYourLesson}
                beforePrice={getLocale("3,000")}
                price={getLocale("2,100")}
            />
            <SocialSection />
        </div >
    );
}

export default SkiSchoolLanding;
