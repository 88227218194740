import {FETCH_DATA_FB} from './shared'

function fetchData(data) {
    return {
        type: FETCH_DATA_FB,
        data: data
    }
}

export function handlefbFetchData(data) {
    return dispatch => {
        dispatch(fetchData(data))
    }
}