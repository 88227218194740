import React from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "./professionalPackages.module.scss";
// import packagesImg2 from "../../../img/slope-pass-packages.jpg";
import useLocale from "../../../lang/useLocale";
// import { useSelector } from "react-redux";

function SlopePassPackage(props) {
  const { getLocale } = useLocale();
  // const lang = useSelector((state) => state.lang);
  // console.log(props.data);
  return (
    <Container maxWidth={false} className={styles.professionalPackages}>
      <Grid
        container
        className={styles.containerGridAll}
        direction="row"
        justify="center"
      >
        {props.data.plugin?.map((plug) => {
          return (
            <React.Fragment key={plug.id}>
              <Grid
                item
                xs={12}
                md={6}
                xl={5}
                className={`${styles.containerImgRight}`}
              >
                <img src={plug.image} alt="img" className={styles.left} />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={5}
                className={`${styles.containerData} ${styles.right}`}
              >
                <Grid item xs={12} className={`${styles.Header}`}>
                  <p>{plug.name}</p>
                </Grid>
                <Grid item xs={12} className={`${styles.description}`}>
                  <p>{plug.description}</p>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.PackagePerPerson}`}
                  alignItems="center"
                >
                  <Grid item xs={12} md={6} className={`${styles.Header}`}>
                    <a
                      href={plug.book}
                      target="_self"
                      rel="noopener noreferrer"
                    >
                      {getLocale("BOOK NOW")}
                    </a>
                  </Grid>
                  <Grid item xs={12} md={6} className={`${styles.Header}`}>
                    <p>
                      {plug.price}
                      <span>/{getLocale("Per Person")} </span>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Container>
  );
}
export default SlopePassPackage;
