import Popup from "../../../General/popup/popup";
import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import useLocale from "../../../../lang/useLocale";
import styles from "./registeration.module.scss";
import img from "../../../../img/freeStyleNightAccessPopUp.png";
import { useSelector } from "react-redux";
import axios from "axios";
import loaderImg from "../../../../img/loader.gif";

const Registration = (props) => {
  const { getLocale } = useLocale();
  const [loader, setLoader] = useState(false);
  const formRef = useRef();
  const lang = useSelector((state) => state.lang);
  const [valueCheckbox, setValueCheckbox] = useState(true);
  const [valid, setValid] = useState(false);
  const [submittingForm, setSubmittingForm] = useState();
  const [disabled, setDisabled] = useState(false);
  const [nameError, setNameError] = useState({ name: "" });
  const [phoneError, setPhoneError] = useState({ phone: "" });
  const [emailError, setEmailError] = useState({ email: "" });
  const [valuef, setValueF] = useState("0");
  const [submitState, setsubmit] = useState(false);
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    member: valuef,
  });
  // console.log(valuef);
  useEffect(() => {
    if (valueCheckbox) {
      setValueF("1");
      // setData({...data, member:"1"})
    } else {
      setValueF("0");
      // setData({...data, member:"0"})
    }
  }, [valueCheckbox, valuef]);
  useEffect(() => {
    if (submitState && data) {
      formValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitState, data]);
  const formValidation = () => {
    setValid(true);
    if (!data["name"]) {
      setValid(false);
      setNameError({
        name: "this field is required",
      });
    } else {
      setNameError({
        name: "",
      });
    }
    if (!data["phone"]) {
      setValid(false);
      setPhoneError({
        phone: "this field is required",
      });
    } else {
      setPhoneError({
        phone: "",
      });
    }
    if (!data["email"]) {
      setValid(false);
      setEmailError({
        email: "this field is required",
      });
    } else {
      setEmailError({
        email: "",
      });
    }
  };
  const submit = (e) => {
    e.preventDefault();
    setsubmit(true);
    formValidation();
    if (valid) {
      sendForm();
      setDisabled(true);
    }
  };
  const sendForm = () => {
    setLoader(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/${props.path}`, data)
      .then((response) => {
        window.dataLayer.push({ event: "Successful Form Submission", "Form Name": props.formName })
        setLoader(false);
        setSubmittingForm(getLocale("Form Submitted Successfully"));
        formRef.current.reset();
        setEmailError({ email: "" });
        setNameError({ name: "" });
        setPhoneError({ phone: "" });
        setDisabled(false);
      })
      .catch((error) => {
        // console.log(error)
        setLoader(false);
        setSubmittingForm(getLocale("Error"));
        setDisabled(false);
      });
  };

  const contactFormChange = (e) => {
    if (e.target.name === "member") {
      setValueCheckbox(!valueCheckbox);
      // console.log(valueCheckbox)
    }
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  return (
    <div className={styles.registerationPackageContainer}>
      <Popup
        popupTitle=""
        popupTriggerTitle={getLocale("Register Now")}
        popupTriggerClass={styles[props.styleName ? props.styleName : "registerationBtn"]}
      >
        <div id="popUpRegisterationProfessionalPackage">
          <Grid container>
            <Grid item md={6} xs={12}>
              <div className="bg">
                <img src={img} alt="skiClup" />
              </div>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              className="choo"
              style={{
                background: "#fff",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
                paddingTop: "50px",
              }}
            >
              <div className="popupRegisterationProfessionalPackageContentContainer">
                <p className="subTitle">{getLocale("REGISTRATION")}</p>
                <h3 className="title">{getLocale(props.formTitle)}</h3>
                <form
                  ref={formRef}
                  onSubmit={(e) => submit(e)}
                  className={`${lang === "en" ? "LEFT" : "RTL"}`}
                >
                  <Grid item xs={10}>
                    <input
                      onChange={contactFormChange}
                      name="name"
                      type="text"
                      placeholder={`${lang === "en" ? "Name" : "الأسم"}`}
                    />
                    {nameError.name && (
                      <div className="error">{nameError.name}</div>
                    )}
                  </Grid>
                  <Grid item xs={10}>
                    <input
                      onChange={contactFormChange}
                      name="email"
                      type="email"
                      placeholder={`${lang === "en" ? "Email" : "البريد الألكتروني"
                        }`}
                    />
                    {emailError.email && (
                      <div className="error">{emailError.email}</div>
                    )}
                  </Grid>
                  <Grid item xs={10}>
                    <input
                      onChange={contactFormChange}
                      name="phone"
                      type="text"
                      placeholder={`${lang === "en" ? "Phone Number" : "رقم الهاتف"
                        }`}
                    />
                    {phoneError.phone && (
                      <div className="error">{phoneError.phone}</div>
                    )}
                  </Grid>
                  <Grid item xs={10}>
                    <input
                      onChange={contactFormChange}
                      name="facebook"
                      type="text"
                      placeholder={`${lang === "en"
                        ? "Facebook Profile Link"
                        : "Facebook رابط الملف الشخصي على"
                        }`}
                    />
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: "14px" }}>
                    <div className="inputContainer">
                      <p className="note">
                        <input
                          name="member"
                          onChange={contactFormChange}
                          // onChange={()=>{setValueCheckbox(!valueCheckbox)}}
                          type="checkbox"
                          value={valuef}
                        />
                        <span className="labelCheckbox">
                          {" "}
                          {getLocale("I am a member of SKI School")}
                        </span>
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <button
                      className="register"
                      disabled={disabled}
                      style={{
                        backgroundColor: disabled && "#b5b5b5",
                        color: disabled && "#3a3a3a",
                      }}
                      type="submit"
                    >{`${lang === "en" ? "Register Now" : "سجل الآن"}`}</button>
                  </Grid>
                  {loader ? (
                    <div style={{ position: "initial" }} className="loader">
                      <img src={loaderImg} alt="loader" />
                    </div>
                  ) : null}
                  <Grid item md={9} xs={12}>
                    <p
                      style={{
                        color: submittingForm === "Error" ? "red" : "#4BB543",
                      }}
                    >
                      {submittingForm}
                    </p>
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </Popup>
    </div>
  );
};
export { Registration };
