import styles from "./PopUpSkiPro.module.scss";
import React, { useRef } from "react";
import Popup from "../../../General/popup/popup";
import { Grid } from "@material-ui/core";
// import ProImageEnglish from '../../../../img/skipro_en.png';
// import ProImageArabic from '../../../../img/skipro_ar.png';
import summerCamp from '../../../../img/summerImg.png'
import useLocale from "../../../../lang/useLocale";
import { useSelector } from "react-redux";
import close from "../../../../img/close-white.svg";
import { Link } from "react-router-dom";

const PopUpSkiPro = (props) => {
  const size = useSelector((state) => state.size);
  const btnRef = useRef();
  const lang = useSelector((state) => state.lang);

  const { getLocale } = useLocale();

  return (
    <div className={styles.popUpSkiProContainer} ref={btnRef}>
      <Popup
        popupTitle=""
        popupTriggerTitle={props.title ? props.title : ""}
        closePopupStyle={`${lang === "en" ? "closePopupLeft" : "closePopupRight"
          }`}
        imgClose={size <= 768 && close}
      >
        <div id="popUpWrapper">
          <Grid container direction={lang === "en" ? "row" : "row-reverse"}>
            <Grid item md={6} xs={12}>
              {/* <img style={{width:'100%'}} src={lang ==="en"?snowPlusImgEn:snowPlusImgAr} alt="skiClup"/> */}
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  padding:
                    lang === "en" ? "10px 0 10px 10px" : "10px 0px 10px 0px",
                }}
                src={summerCamp}
                alt="skiClup"
              />
            </Grid>
            <Grid item md={6} xs={12} className="bgContent">
              <div className="popupContentContainer">
                <p className="subTitle">{getLocale("Introducing")}</p>
                <h3 className="title">{getLocale("SUMMER CAMP IS BACK")}</h3>
                {/* <p className="price">
                  {getLocale("380")}
                  <span>/{getLocale("Per Person")}</span>
                </p>{" "} */}
                <p className="content">
                  {getLocale("Popup SKI Pro Package Pragraph 2")}
                </p>

                {/* <span className="subContent">
                  {getLocale("Book now and enjoy our Summer Snow Plus offer!")}
                </span> */}
                <Grid container alignItems="center" justify="space-between">
                  <Grid item md={7} xs={7}>
                    <Link
                      to={`/${lang}/summer-camp`}
                    >
                      <button className={styles.registerBtn}>
                        {getLocale("BOOK NOW")}
                      </button>
                    </Link>
                  </Grid>
                  {/* <Grid item md={4} xs={4}>
                    <button className="learnMoreBtn">
                      <Link
                        to={{
                          pathname: `/${lang}/prices`,
                          state: { openRegisterForm: true },
                        }}
                      >
                        {getLocale("learnmore")}
                      </Link>
                    </button>
                  </Grid> */}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Popup>
      {/* <div className={styles.popUpContainer}>
            <Grid container>
            <Grid item xs={5}>
                <img src={popUpImg} alt="skiClup"/>
            </Grid>
            <Grid item xs={7}>
                <p>Introducing</p>
                <h3>Ski Club Program</h3>
                <p>We’re excited to announce this 2021’s “Ski Club” Program. Ski School is offering the “Ski Club” program for those who want to become professional skiers and snowboarders within an intense program.</p>
                <span>Join our Ski Club and become a pro in 10 weeks!</span>
                <button>Register Now</button>
            </Grid>
            </Grid>
            </div> */}
    </div>
  );
};
export default PopUpSkiPro;
