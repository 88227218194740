import React, { useState, Fragment } from "react";
import styles from "../penguins.module.scss";
import { Container, Grid } from "@material-ui/core";

export default function Video(props) {
  const [placeHolder, setPlaceHolder] = useState(true);
  function handlePlaceHolder() {
    setPlaceHolder(!placeHolder);
  }
  return (
    <div className={styles.video}>
      <Container maxWidth="lg">
        <Grid container justify="center" alignItems="center" spacing={1}>
          <Grid item xs={12} md={6} style={{ position: "relative" }}>
            {
              <Fragment>
                <img
                  src={props.data.image}
                  alt="مسيرة البطاريق في سكي"
                  onClick={handlePlaceHolder}
                  className={styles.placeHolder}
                />
              </Fragment>
            }
          </Grid>
          <Grid item xs={12} md={6}>
            <h4>{props.data.title}</h4>
            <h2>{props.data.subtitle}</h2>
            <p>{props.data.description}</p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
