import { WEB_FAIL, WEB_SUCCESS, WEB_REQUEST } from "../constants/webConstants";

export const webReducer = (state = { loading: true, data: {} }, action) => {
  switch (action.type) {
    case WEB_REQUEST:
      return { loading: true };
    case WEB_SUCCESS:
      return { loading: false, data: action.payload };
    case WEB_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
