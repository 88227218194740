import React, { useEffect, useRef, useState } from "react";
import SwiperSlider from "../../General/swiper/swiperTest";
import { Container, Grid } from "@material-ui/core";
import snow from "../../../img/snowPackages.JPG";
import styles from "../packages.module.scss";
// import optionlist1 from "../../../img/optionlist1.jpg";
// import table1 from "../../../img/table1.jpg";
// import table2 from "../../../img/table2.JPG";
// import table3 from "../../../img/table3.jpg";
// import table4 from "../../../img/table4.jpg";
// import table5 from "../../../img/table5.jpg";
// import table6 from "../../../img/table6.jpg";
// import table7 from "../../../img/table7.jpg";
// import locker from "../../../img/locker.jpg";
// import gloves from "../../../img/gloves.jpg";
// import hotchoclate from "../../../img/hotchoclate.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import useLocale from "../../../lang/useLocale";
import { useSelector } from "react-redux";

export default function Comparison(props) {
  // console.log(props.data.plugin);
  const { getLocale } = useLocale();
  const lang = useSelector((state) => state.lang);

  const mySwiper = useRef();
  const myElement = useRef();
  useEffect(() => {
    // add and remove class active for arrow next and prev on scroll
    if (myElement.current) {
      let myElementCurrent = myElement.current;
      window.onscroll = () => {
        let top = myElementCurrent.getBoundingClientRect().top;
        let bottom = myElementCurrent.getBoundingClientRect().bottom;
        if (top <= 300 && bottom >= 450) {
          // console.log("in")
          myElementCurrent
            .querySelector(".swiper-button-next")
            .classList.add("active");
          myElementCurrent
            .querySelector(".swiper-button-prev")
            .classList.add("active");
        } else {
          // console.log("out")
          myElementCurrent
            .querySelector(".swiper-button-next")
            .classList.remove("active");
          myElementCurrent
            .querySelector(".swiper-button-prev")
            .classList.remove("active");
        }
      };
    }
  }, [myElement]);
  // const settings = {
  //   arrows: true,
  //   loop: false,
  //   spaceBetween: 10,
  //   // autoplay: {
  //   // 	delay: 10000,
  //   // },
  //   breakpoints: {
  //     320: {
  //       slidesPerView: 1.1,
  //     },
  //     768: {
  //       slidesPerView: 1.1,
  //     },
  //     1025: {
  //       slidesPerView: 3,
  //     },
  //   },
  //   autoplayDisableOnInteraction: true,
  //   bullets: false,
  // };
  const [featuresList, setFeaturesList] = useState([]);
  useEffect(() => {
    if (props.data.plugin) {
      setFeaturesList(
        props.data.plugin.map((plug) => {
          return plug.feature;
        })
      );
    }
  }, [props.data.plugin]);


  const settings = {
    arrows: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      />
    );
  }

  return (
    <Container className={styles.comparison}>
      <Grid
        container
        spacing={1}
        direction={lang === "en" ? "row" : "row-reverse"}
      >
        <Grid item xs={6} md={4} className={styles.snow}>
          <div className={styles.snowWrap}>
            <Grid container justify="center" alignItems="center">
              <img src={snow} alt="snow" />
            </Grid>
            <h6>{getLocale("What's included")}</h6>
          </div>
          <Grid container alignItems="center">
            {/* {featuresList[0]?.slice(0, featuresList[0].length - 1).map((feature) => { */}
            {featuresList[0]?.map((feature) => {
              return (
                <Grid
                  key={feature.id}
                  item
                  container
                  alignItems="center"
                  justify="center"
                  className={styles.row}
                >
                  <Grid item xs={4}>
                    <img src={feature.image} alt="img" />
                  </Grid>
                  <Grid item xs={8}>
                    <div className={styles.snowDesc}>{feature.name}</div>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          xs={6}
          md={8}
          item
          style={{ paddingTop: "0px", direction: lang === "ar" && "rtl" }}
          ref={myElement}
        >
          <SwiperSlider
            settings={settings}
            mySwiper={mySwiper}
            containerClass={styles.swiperContainerClass}
          >
            {props.data.plugin
              ?.filter((plug) => plug.id !== 5)
              .map((plug) => {
                return (
                  <div
                    key={plug.id}
                    className={`${styles.packageSlide}  swiper-slide`}
                  >
                    <div className={styles.header}>
                      {plug.sale === 1 && (
                        <span className={styles.redTop}>
                          {getLocale("Online Booking Offer")}
                        </span>
                      )}
                      <h2>{plug.name}</h2>
                      {plug.sale === 1 && (
                        <del>
                          <h6
                            style={{ fontSize: "16px", color: "#808080" }}
                            className={plug.sale === 1 ? "" : ""}
                          >
                            500 {getLocale("LE")}
                          </h6>
                        </del>
                      )}
                      <h6 className={plug.sale === 1 ? styles.redText : ""}>
                        {plug.price} {getLocale("LE")}
                      </h6>
                      {plug.availability === "Booking Available" ? (
                        <a
                          href={plug.book}
                          target="_self"
                          rel="noopener noreferrer"
                        >
                          {getLocale("BOOK NOW")}
                        </a>
                      ) : (
                        <button className={styles.book}>
                          {getLocale("Booking Unavailable")}
                        </button>
                      )}
                    </div>
                    {/* {plug.feature?.slice(0, plug.feature.length - 1).map((featureItem) => { */}
                    {plug.feature?.map((featureItem) => {
                      return (
                        <div key={featureItem.id} className={styles.item}>
                          {featureItem.text === "Select one activity" ? null : (
                            <FontAwesomeIcon
                              icon={
                                featureItem.text === "Unlimited" ||
                                featureItem.text === "Free"
                                  ? faCheck
                                  : featureItem.text === "Not Free" ||
                                    featureItem.text === "Unavailable"
                                  ? faTimes
                                  : ""
                              }
                            />
                          )}
                          <span>{getLocale(featureItem.text)}</span>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </SwiperSlider>
        </Grid>
      </Grid>
    </Container>
  );
}
