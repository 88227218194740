import React from "react";
import styles from "../penguins.module.scss";
import { Grid, Container } from "@material-ui/core";
// import { Link } from "react-router-dom";
import useLocale from "../../../lang/useLocale";
// import { useSelector } from "react-redux";

export default function PenguinsTickets(props) {
  // const { data } = props.data;
  // console.log(props.data);
  // const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();
  return (
    <div
      className={`${styles.ticketsPenguins} ${styles.tickets} ${styles.ticketsWrap} penguins`}
    >
      <Container>
        <div className={styles.ticketsTitles}>
          <h4>{getLocale("Visit the penguins")}</h4>
        </div>
        <Grid
          container
          spacing={1}
          className={styles.cardsWrap}
          justify="center"
        >
          {Array.isArray(props.data.plugin) &&
            props.data.plugin?.map((packageItem) => {
              return (
                <Grid
                  key={packageItem.id}
                  item
                  xs={12}
                  sm={6}
                  className={styles.card}
                >
                  <Grid
                    container
                    justify="space-between"
                    direction="column"
                    className="cardHeight"
                  >
                    <Grid item>
                      <h5 className={`${styles.title} ${styles.titleCard}`}>
                        {packageItem.name}
                      </h5>
                      <h4 className={styles.price}>
                        <span>{packageItem.price}</span>
                        <span
                          className={styles.egp}
                          style={{ margin: "0 5px" }}
                        >
                          /{getLocale("LE")}
                        </span>
                      </h4>
                      <div className={styles.description}>
                        {packageItem.description}
                      </div>
                    </Grid>
                    <Grid item>
                      {packageItem.availability === "Booking Available" ? (
                        <a
                          href={packageItem.book}
                          target="_self"
                          rel="noopener noreferrer"
                          className={styles.book}
                        >
                          {getLocale("BOOK NOW")}
                        </a>
                      ) : (
                        <button
                          className={styles.book}
                          style={{ color: "#8c8c8c" }}
                        >
                          {getLocale("Booking Unavailable")}
                        </button>
                      )}
                      {/* <a
                        // href={`https://booking.skiegy.com/${lang}/snow-penguins/booking/peng-friend-encounter`}
                        // href={packageItem.book}
                        target="_self"
                        rel="noopener noreferrer"
                        className={styles.book}
                      >
                        {getLocale("BOOK NOW")}
                      </a> */}
                      {/* <button className={styles.book}>
                        {getLocale("Booking Unavailable")}
                      </button> */}
                      {/* <button className={styles.book}>{getLocale("Booking Unavailable")}</button> */}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

          {/* //////////////next card */}
          {/* <Grid item xs={12} sm={6} className={styles.card}>
            <Grid
              container
              justify="space-between"
              direction="column"
              className="cardHeight"
            >
              <Grid item>
                <h5 className={`${styles.title} ${styles.titleCard}`}>
                  {getLocale("snowpremium")}
                </h5>
                <h4 className={styles.price}>
                  <span>600</span>
                  <span className={styles.egp} style={{ margin: "0 5px" }}>
                    /{getLocale("LE")}
                  </span>
                </h4>
                <div className={styles.description}>
                  {getLocale("SNOW PLUS PACKAGE Descriptiopn")}
                </div>
              </Grid>
              <Grid item> */}
          {/*<a 
									href={`https://booking.skiegy.com/${lang}/snow-park/booking/snow-premium`}
									target="_self"
									rel="noopener noreferrer"
									className={styles.book}
								>
									{getLocale("BOOK NOW")}
								</a>*/}
          {/* <button className={styles.book}>
                  {getLocale("Booking Unavailable")}
                </button>
              </Grid>
            </Grid>
          </Grid> */}
          {/* //////////////////next */}
        </Grid>
      </Container>
    </div>
  );
}
