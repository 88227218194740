import { CHANGE_SIZE } from './shared'

function screenSize(size) {
    return {
        type: CHANGE_SIZE,
        size
    }
}

export function handleSize(size) {
    return dispatch => {
        dispatch(screenSize(size))
    }
}