export const ACTIVITY_LIST_REQUEST = "ACTIVITY_LIST_REQUEST";
export const ACTIVITY_LIST_SUCCESS = "ACTIVITY_LIST_SUCCESS";
export const ACTIVITY_LIST_FAIL = "ACTIVITY_LIST_FAIL";

export const LIST_OPTIONS_REQUEST = "LIST_OPTIONS_REQUEST";
export const LIST_OPTIONS_SUCCESS = "LIST_OPTIONS_SUCCESS";
export const LIST_OPTIONS_FAIL = "LIST_OPTIONS_FAIL";

export const FILTERED_DATA_REQUEST = "FILTERED_DATA_REQUEST";
export const FILTERED_DATA_SUCCESS = "FILTERED_DATA_SUCCESS";
export const FILTERED_DATA_FAIL = "FILTERED_DATA_FAIL";
