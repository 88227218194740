import { FETCH_DATA_IG } from '../actions/shared'
const initiaValue = { data: [] }

export default function ig(state = initiaValue, action) {

    switch (action.type) {
        case FETCH_DATA_IG:
            return { data: [...state.data, ...action.data.data], next: action.data.paging.next }
        default:
            return state
    }
}