import axios from "axios";
import { store } from "../../index";
import { WEB_FAIL, WEB_REQUEST, WEB_SUCCESS } from "../constants/webConstants";

export const webAction = () => async (dispatch) => {
  dispatch({
    type: WEB_REQUEST,
  });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/pages?find[client]=web`,
      {
        headers: {
          "Content-Language": `${store.getState().lang}`,
        },
      }
    );
    dispatch({ type: WEB_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: WEB_FAIL, payload: error.message });
  }
};
