import React from "react";
import { Container, Grid } from "@material-ui/core";
import ProImageEnglish from '../../../img/skipro_en.png';
import ProImageArabic from '../../../img/skipro_ar.png';
import styles from "../skiSchool.module.scss";
import useLocale from "../../../lang/useLocale";
import { useSelector } from "react-redux";

export default function MeetSchoolTrainers(props) {
    const { getLocale } = useLocale();
    const lang = useSelector((state) => state.lang);
    return (
        <div className={styles.pro} dir={getLocale('dir')}>
            <Container maxWidth="lg">
                <Grid container justify="center" alignItems="center" spacing={1}>
                    <Grid item xs={12} md={6} style={{ position: "relative" }}>
                        <img
                            style={{ borderRadius: '5px' }}
                            src={lang === 'en' ? ProImageEnglish: ProImageArabic}
                            alt="pro"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ position: "relative" }}>
                        <h4>{getLocale('SKI PRO PACKAGE')}</h4>
                        <h2>750 {getLocale('LE')}</h2>
                        <p>{getLocale('SKI Pro Package Pragraph 1')}</p>
                        < br />
                        <p>{getLocale('SKI Pro Package Pragraph 2')}</p>
                        <br />
                        <p>{getLocale("SKI Pro Package Pragraph 3")}</p>
                        <a
                            href={`https://booking.skiegy.com/${lang === "en" ? "en" : "ar"}/ski-school/booking/skipro`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {getLocale("BOOK NOW")}
                        </a>
                        {/* <a
                            href={`https://booking.skiegy.com/${lang === "en" ? "en" : "ar"}/ski-school/booking/skipro`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {getLocale("BOOK NOW")}
                        </a> */}
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
