import React, { Fragment, useState } from "react";
import styles from "./Experience.module.scss";
import { Container, Grid } from "@material-ui/core";
// import videoImg from '../../../../img/imgPlaceHolder.png'
import { useSelector } from "react-redux";

function Experience(props) {
  const [placeHolder, setPlaceHolder] = useState(true);
  const lang = useSelector((state) => state.lang);
  // console.log(props.data);
  function handlePlaceHolder() {
    setPlaceHolder(!placeHolder);
  }
  // useEffect(()=>{
  //     if(lang){
  //         dispatch(sectionAbout());
  //     }
  // },[dispatch,lang])
  return (
    <Container maxWidth="lg" className={`${styles.containerExperience}`}>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={1}
        direction={lang === "en" ? "row" : "row-reverse"}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          className={`${styles.containerData}`}
        >
          <Grid item className={`${styles.containerHeader}`} xs={12}>
            <p>{props.data.title}</p>
          </Grid>
          <Grid item className={`${styles.containerDescription}`} xs={12}>
            {props.data.description}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={`${styles.containerVideo}`}>
          {placeHolder ? (
            <Fragment>
              <img
                src={props.data.image}
                alt="video"
                onClick={handlePlaceHolder}
                className={styles.placeHolder}
              />
            </Fragment>
          ) : (
            <iframe
              title="video"
              width="100%"
              height="376"
              src={props.data.video}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
export default Experience;
