import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import icon from "../../../img/faqArrow.svg";

export default function PopupTab(props) {
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <p>{props.question}</p>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <p>{props.answer}</p>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
