import React, { useState, useEffect } from "react";
import HeaderPanel from "../General/headerPanel/headerPanel";
import SocialSection from "../General/social-section/social-section";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions'
import Experience from "./SubComponents/Experience/Experience";
import Slider from "./SubComponents/Slider/Slider";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
function AboutUs() {
  const lang = useSelector((state) => state.lang);

  const dataAbout = useSelector((state) => state.webReducer);
  const [aboutData, setAboutData] = useState([]);
  const { data } = dataAbout;
  // console.log(aboutData);
  useEffect(() => {
    if (Array.isArray(data)) {
      setAboutData(data[0]);
    }
  }, [data, aboutData]);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://skiegy.com/${lang}/aboutus`} />
      </Helmet>
      <HeaderPanel
        containerCard
        panal={aboutData.image}
        subTitle={aboutData.title}
        title={aboutData.subtitle}
        description={aboutData.description}
      />
      <Experience
        data={Array.isArray(aboutData.sections) && aboutData.sections[0]}
      />
      <Slider
        data={Array.isArray(aboutData.sections) && aboutData.sections[1]}
      />
      <SocialSection />
      {/* <OurAttractions/> */}
    </div>
  );
}

export default AboutUs;
