import React, { useState, useEffect } from "react";
import styles from "./penguins.module.scss";
// import Banner from '../General/headerPanel/headerPanel'
// import penguinsBanner from '../../img/penguinsBanner.jpg'
// import { Container } from '@material-ui/core'
import SocialSection from "../General/social-section/social-section";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions'
import PenguinsTickets from "./subComponents/penguinTickets";
import March from "./subComponents/video";
import MeetPenguins from "./subComponents/meetPenguins";
import MeetTrainers from "./subComponents/meetTrainers";
import VideoBasic from "./video/video";
import { Container, Grid } from "@material-ui/core";
import useLocale from "../../lang/useLocale";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import {
//   pageHeaderPenguin,
//   AllPenguinsActions,
//   allTeamsActions,
//   penguinsPackagesActions,
//   sectionPenguin,
// } from "../../redux/actions/penguinActions";
export default function Penguins() {
  const { getLocale } = useLocale();
  const lang = useSelector((state) => state.lang);
  const dataPenguins = useSelector((state) => state.webReducer);
  const [penguinsData, setPenguinsData] = useState([]);
  const { data } = dataPenguins;
  // console.log(penguinsData);
  useEffect(() => {
    if (Array.isArray(data)) {
      setPenguinsData(data[5]);
    }
  }, [data, penguinsData]);
  // console.log(
  //   pageHeader,
  //   allPenguins,
  //   allTeams,
  //   penguinPackages,
  //   sectionPenguinData
  // );
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (lang) {
  //     Promise.all([
  //       dispatch(pageHeaderPenguin()),
  //       dispatch(AllPenguinsActions()),
  //       dispatch(allTeamsActions()),
  //       dispatch(penguinsPackagesActions()),
  //       dispatch(sectionPenguin()),
  //     ]);
  //   }
  // }, [dispatch, lang]);
  return (
    <div className={styles.pricing}>
      <Helmet>
        <meta
          name="description"
          content={getLocale(
            "It's time to enjoy the penguin program and meet Sultan, our star penguin, at the snow park visit ski Egypt to know more about penguins program & price"
          )}
        />
        <title>
          {getLocale(
            "Meet Penguins Now! | Enjoy Snow Penguin Program | Ski Egypt"
          )}
        </title>
        <link rel="canonical" href={`https://skiegy.com/${lang}/penguins`} />
      </Helmet>
      <VideoBasic
        title="THE PENGUINS' JOURNEY"
        img={penguinsData.image}
        videoUrl={penguinsData.video}
        // "https://www.youtube.com/embed/Mdr-Cof6wN4"
      />
      <Container className={styles.penguinsDesc}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={10}>
            <h1>{penguinsData.title}</h1>
            <p>{penguinsData.description}</p>
          </Grid>
        </Grid>
      </Container>
      <March
        data={Array.isArray(penguinsData.sections) && penguinsData.sections[0]}
      />
      <MeetPenguins
        data={Array.isArray(penguinsData.sections) && penguinsData.sections[1]}
      />
      <MeetTrainers
        data={Array.isArray(penguinsData.sections) && penguinsData.sections[2]}
      />
      <PenguinsTickets
        data={Array.isArray(penguinsData.sections) && penguinsData.sections[3]}
      />
      <SocialSection />
    </div>
  );
}
