import React, { Fragment, useState } from "react";
import styles from "./video.module.scss";
import { Container, Grid } from "@material-ui/core";
import playIcon from "../../../img/playIcon.svg";

function Video(props) {
  const [placeHolder, setPlaceHolder] = useState(true);
  function handlePlaceHolder() {
    setPlaceHolder(!placeHolder);
  }
  return (
    <Container maxWidth="lg" className={`${styles.containerVideoSchool}`}>
      <Grid container justify="center" alignItems="center" spacing={1}>
        <Grid
          container
          item
          xs={12}
          md={8}
          className={`${styles.containerData}`}
        >
          <Grid item className={`${styles.containerHeader}`} xs={12}>
            <p>{props.title}</p>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} className={`${styles.containerVideo}`}>
          {placeHolder ? (
            <Fragment>
              <div onClick={handlePlaceHolder} className={styles.wrap}>
                <img src={playIcon} alt="icon" className={styles.icon} />
                <img
                  src={props.img ? props.img : undefined}
                  alt={props.alt ? props.alt : "video"}
                  className={styles.placeHolder}
                />
              </div>
            </Fragment>
          ) : (
            <iframe
              title="video"
              width="100%"
              height="376"
              src={props.videoUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
export default Video;
