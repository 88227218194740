export default function useGroupData(lang) {
	return [
		//Skiing Discovery for 3-6 years
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Discovery",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-discovery-lesson-youngster-3-6-yrs`,
				},
			],
		},
		// Discovery for 7-13 years
		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Discovery",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-discovery-lesson-child-7-13-yrs`,
				},
			],
		},
		// Discovery for Adults years
		{
			activity: "Skiing",
			age: "Adults",
			level: "Discovery",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-discovery-lesson-adult`,
				},
			],
		},
		//Skiing Beginner for 3-6 years
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Beginner",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/slope/booking/ski-lesson-beginner-youngster-3-6-yrs`,
				},
			],
		},
		// Beginner for 7-13 years
		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Beginner",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-beginner-child-7-13-yrs`,
				},
			],
		},
		// Beginner for Adults years
		{
			activity: "Skiing",
			age: "Adults",
			level: "Beginner",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-beginner-adult`,
				},
			],
		},
		//Skiing Level 1 for 3-6 years
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Level 1",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-1-youngster-3-6-yrs`,
				},
			],
		},
		// Level 1 for 7-13 years
		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Level 1",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-1-child-7-13-yrs`,
				},
			],
		},
		// Level 1 for Adults years
		{
			activity: "Skiing",
			age: "Adults",
			level: "Level 1",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-1-adult`,
				},
			],
		},

		//Skiing Level 2 for 3-6 years
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Level 2",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-2-youngster-3-6-yrs`,
				},
			],
		},
		// Level 2 for 7-13 years
		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Level 2",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-2-child-7-13-yrs`,
				},
			],
		},
		// Level 2 for Adults years
		{
			activity: "Skiing",
			age: "Adults",
			level: "Level 2",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-2-adult`,
				},
			],
		},

		//Skiing Level 3 for 3-6 years
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Level 3",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-3-youngster-3-6-yrs`,
				},
			],
		},
		// Level 3 for 7-13 years
		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Level 3",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-3-child-7-13-yrs`,
				},
			],
		},
		// Level 3 for Adults years
		{
			activity: "Skiing",
			age: "Adults",
			level: "Level 3",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-3-adult`,
				},
			],
		},

		//Skiing Level 4 for 3-6 years
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Level 4",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-4-youngster-3-6-yrs`,
				},
			],
		},
		// Level 4 for 7-13 years
		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Level 4",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-4-child-7-13-yrs`,
				},
			],
		},
		// Level 4 for Adults years
		{
			activity: "Skiing",
			age: "Adults",
			level: "Level 4",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-lesson-level-4-adult`,
				},
			],
		},

		// Snowboarding Discovery for 8-13 years
		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Discovery",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-discovery-lesson-child-8-13-yrs`,
				},
			],
		},
		// Discovery for Adults years
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Discovery",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-discovery-lesson-adult`,
				},
			],
		},
		// Snowboarding Beginner for 8-13 years
		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Discovery",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-discovery-lesson-child-8-13-yrs`,
				},
			],
		},
		// Beginner for Adults years
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Beginner",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-beginner-adult`,
				},
			],
		},

		// Snowboarding Beginner for 8-13 years
		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Beginner",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-beginner-child-8-13-yrs`,
				},
			],
		},
		// Beginner for Adults years
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Beginner",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-beginner-adult`,
				},
			],
		},

		// Snowboarding Level 1 for 8-13 years
		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Level 1",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-level-1-child-8-13-yrs`,
				},
			],
		},
		// Level 1 for Adults years
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Level 1",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-level-1-adult`,
				},
			],
		},

		// Snowboarding Level 2 for 8-13 years
		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Level 2",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-level-2-child-8-13-yrs`,
				},
			],
		},
		// Level 2 for Adults years
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Level 2",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-level-2-adult`,
				},
			],
		},

		// Snowboarding Level 3 for 8-13 years
		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Level 3",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-level-3-child-8-13-yrs`,
				},
			],
		},
		// Level 3 for Adults years
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Level 3",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-level-3-adult`,
				},
			],
		},
		// Snowboarding Level 4 for 8-13 years
		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Level 4",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-level-4-child-8-13-yrs`,
				},
			],
		},
		// Level 4 for Adults years
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Level 4",
			privacy: "Group",
			lessons: [
				{
					time: null,
					link: `https://booking.skiegy.com/${lang}/snowboard/booking/snowboard-lesson-level-4-adult`,
				},
			],
		},
	];
}
