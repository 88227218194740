import React, { Fragment } from 'react'

export default function Rules(props) {
    if (props.lang === 'en') {
        return (
            <Fragment>
                <li>
                    The safety and comfort of all guests is of prime importance to Ski Egypt. Guests are always required to familiarize themselves with the relevant activity rules and regulations and to adhere to them at all times:
                </li>
                <li>
                    Entry to the Snow Park and Ski Slope are only permitted with a valid pass. Guests must be able to display their passes upon request.
                </li>
                <li>
                    The minimum age to enter the Snow Park is 2 years old. Children under the age of 16 must be accompanied by a responsible adult at all times.
                </li>
                <li>
                    The minimum age for Skiing lessons is 3 years old. The minimum age for Snowboard lessons is 8 years old. All children must provide signed disclaimers by their guardian/parent who take full responsibility.
                </li>
                <li>
                    Adults/Guardians/Parents are responsible for the supervision and conduct of their children. Children must be accompanied at all times by an appropriate guardian who is at least 16 years old.
                </li>
                <li>
                    Proper conduct and respect for other guests and our Snow Crew must be shown at all times. The Management of Ski Egypt reserves the right to refuse the entry of any guest to protect the safety and security of other guests and our Snow Crew. An explanation for refusal of entry is not mandatory. Unacceptable behavior will result in guests being asked to leave Ski Egypt and if necessary, Ski Egypt may involve the relevant outside authorities. Guests are required to accept and agree to comply with Ski Egypt’s rules and regulations and to follow all directions and instructions given by Ski Egypt’s staff.
                </li>
                <li>
                    Entry to the Snow Park, Ski Slope, lifts, all rides and attractions is at the guest’s own risk. Ski Egypt’s owners, management and Snow Crew take no responsibility for personal injury, injury to others and the damage to or loss and theft of personal equipment, clothing and personal belongings. It is the responsibility of all guests to recognize their own abilities and act accordingly.
                </li>
                <li>
                    Helmets are COMPULSORY for all guests under 14 years old in all snow areas and for all guests on some rides. Helmets are also COMPULSORY for ALL guests participating in freestyle skiing, snowboarding activities, racing, ski and snowboard lessons and other specific attractions. Ski Egypt strongly recommends the use of helmets for all guests taking part in recreational skiing and snowboarding.
                </li>
                <li>
                    Ski Egypt is a cold environment. Entry to the Snow Park and Ski Slope is only allowed in waterproof winter attire. It is advised that children between the ages of 2 and 5 stay for shorter periods in the Snow Park. Gloves are compulsory due to the cold environment and your safety.
                </li>
                <li>
                    Ski Egypt is a cold environment. Entry to the Snow Park and Ski Slope is only
                    allowed in waterproof winter attire. It is advised that children between the
                    ages of 2 and 5 stay for shorter periods in the Snow Park. Gloves are compulsory
                    due to the cold environment and your safety.
                </li>
                <li>
                    Guests who are pregnant or have an existing medical condition that may affect their safety are not allowed in the snow park unless approved by a physician prior to visiting Ski Egypt. Participation in activities in the Snow Park by guests who are pregnant is entirely at their own risk and Ski Egypt accepts no liability for consequences arising from their participation.
                </li>
                <li>
                    No food or drink is to be taken into the Snow Park. Drinking and eating in the snow area is only permitted in the cafés.
                </li>
                <li>
                    For your safety and security Ski Egypt is monitored by CCTV cameras.
                </li>
                <li>
                    Some rides and attractions have height restrictions and more information on these are displayed on the adjacent notice and at each ride or attraction.
                </li>
                <li>
                    During busy periods a queueing system may operate at some rides. Queue jumping is not permitted and may result in guests being asked to leave Ski Egypt.
                </li>
                <li>
                    In the event that a guest is injured or feels unwell, First Aid facilities are available for the treatment of minor injuries and ailments. If required, guests who are injured or who are feeling unwell will be transferred to a local hospital. Any guest who refuses First Aid assistance or to take advice given by Ski Egypt’s First Aiders will be required to complete a Waiver and by doing so will be deemed to have accepted full responsibility for any consequences arising from such refusal.
                </li>
                <li>
                    Ski Egypt reserves the right to ask any Guest to leave the Ski Slope if the Guest is considered to be unable to meet the minimum skill requirements, such decisions will be made at the sole discretion of the Management of Ski Egypt and cost of the Guest’s ticket will not be refunded.
                </li>
                <li>
                    Any Guest who is considered to be skiing or riding dangerously will be asked to leave the slope immediately and no refunds will be considered.
                </li>
                <li>
                    Ski Egypt reserves the right not to refund the cost of the entry ticket to any Guest who is unable to comply with Ski Egypt’s age and height restrictions and minimum skill levels.
                </li>
                <li>
                    Smoking is prohibited in all areas of Ski Egypt.
                </li>
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <li>
                    تعد سلامة وراحة جميع الضيوف ذات أهمية قصوى لسكي مصر. يُطلب من الضيوف دائمًا التعرف على قواعد ولوائح الأنشطة ذات الصلة والالتزام بها في جميع الأوقات :
                </li>
                <li>
                    لا يُسمح بالدخول إلى الحديقة الثلجية ومنحدر التزلج إلا بتصريح صالح و من حق عاملين سكي ايجيبت طلب اظهار التذكرة, وعلي الزائر ان يلتزم بذلك وقت الحاجة.
                </li>
                <li>
                    الحد الأدنى لسن الدخول إلى الحديقة الثلجية هو سنتان. يجب أن يكون الأطفال الذين تقل أعمارهم عن 16 عامًا برفقة شخص بالغ مسؤول في جميع الأوقات.
                </li>
                <li>
                    الحد الأدنى لسن دروس سكي هو 3 سنوات. الحد الأدنى لسن دروس سنوبورد هو 8 سنوات. يجب على جميع الأطفال تقديم إخلاء مسؤولية موقع من ولي الأمر/ الوالد الذي يتحمل المسؤولية الكاملة.
                </li>
                <li>
                    الكبار/الأوصياء/أولياء الأمور مسؤولون عن الإشراف وسلوك أطفالهم. يجب أن يرافق الأطفال في جميع الأوقات وصي مناسب يبلغ من العمر أكثر من 16 عامًا.
                </li>
                <li>
                    يجب إظهار السلوك والاحترام المناسبين للضيوف الآخرين وطاقم العمل لدينا في جميع الأوقات. تحتفظ إدارة سكي مصر بحق رفض دخول أي ضيف لحماية سلامة وأمن الضيوف الآخرين وطاقمنا الثلجي. تفسير رفض الدخول ليس إلزاميا. سيؤدي السلوك غير المقبول إلى مطالبة الضيوف بمغادرة سكي مصر ، وإذا لزم الأمر ، قد يشمل سكي مصر السلطات الخارجية ذات الصلة. يجب على الضيوف الموافقة على الامتثال لقواعد وأنظمة سكي مصر واتباع جميع التوجيهات والتعليمات التي يقدمها موظفو سكي مصر.
                </li>
                <li>
                    الدخول إلى منتزه الثلج ومنحدر التزلج والمصاعد وجميع الألعاب على مسؤولية الضيف نفسه. لا يتحمل ملاك وإدارة سكي مصر و طاقم العمل المسؤولية عن الإصابة الشخصية او إصابته للآخرين وتلف أو فقدان المعدات والملابس والممتلكات الشخصية. يتحمل جميع الضيوف مسؤولية التعرف على قدراتهم والعمل وفقًا لذلك.
                </li>
                <li>
                    الخوذات إلزامية لجميع الضيوف الذين تقل أعمارهم عن 14 عامًا في جميع المناطق الثلجية ولجميع الضيوف في بعض الألعاب. الخوذات إلزامية أيضًا لجميع الضيوف المشاركين في أنشطة التزلج الحر، وأنشطة التزلج على الجليد، ودروس السكي والسنوبورد وغيرها من الأنشطة المحددة. توصي سكي مصر بشدة باستخدام الخوذ لجميع الضيوف المشاركين في التزلج الترفيهي سواء سكي أو سنوبورد.
                </li>
                <li>
                    سكي مصر بيئة باردة. يُسمح بالدخول إلى الحديقة الثلجية ومنحدر التزلج فقط في الملابس الشتوية المقاومة للماء. يُنصح بأن الأطفال الذين تتراوح أعمارهم بين 2 و 5 سنين البقاء لفترات أقصر في حديقة الثلج. القفازات إلزامية بسبب البيئة الباردة ولأمانك.
                </li>
                <li>
                    لا يسمح للضيوف الحوامل أو الذين يعانون من حالة طبية حالية قد تؤثر على سلامتهم في الحديقة الثلجية إلا بموافقة من الطبيب قبل زيارة سكي مصر. المشاركة في الأنشطة في الحديقة الثلجية من قبل الضيوف الحوامل هي على مسؤوليتهم الخاصة بالكامل ولا يقبل سكي مصر أي مسؤولية عن العواقب الناشئة عن مشاركتهم.
                </li>
                <li>
                    لا يتم تناول أي طعام أو شراب في الحديقة الثلجية. يُسمح بالشرب والأكل في منطقة الثلج فقط في المقاهي.
                </li>
                <li>
                    من أجل سلامتك وأمنك ، تتم مراقبة سكي مصر بواسطة كاميرات مراقبة عالية الجودة.
                </li>
                <li>
                    بعض الألعاب والأنشطة لها قيود على الطول ويتم عرض مزيد من المعلومات حولها في الإشعار المجاور وفي كل رحلة أو نشاط.
                </li>
                <li>
                    خلال فترات الانشغال ، قد يعمل نظام الانتظار في بعض الأنشطة. القفز في قائمة الانتظار غير مسموح به وقد يؤدي إلى مطالبة الضيوف بمغادرة سكي مصر.
                </li>
                <li>
                    في حالة إصابة الضيف أو شعوره بتوعك، تتوفر مرافق الإسعافات الأولية لعلاج الإصابات والأمراض البسيطة. إذا لزم الأمر، سيتم نقل الضيوف المصابين أو الذين يشعرون بتوعك إلى مستشفى محلي. سيطلب من أي ضيف يرفض مساعدة الإسعافات الأولية أو أخذ النصيحة المقدمة من المسعفين إتمام تنازل، ومن خلال القيام بذلك، سيتم قبوله كامل المسؤولية عن أي عواقب تنشأ عن هذا الرفض.
                </li>
                <li>
                    سيُطلب من أي ضيف يُعتبر متزلجًا أو يركب بشكل خطير أن يغادر المنحدر على الفور ولن يتم النظر في أي مبالغ مستردة.
                </li>
                <li>
                    تحتفظ سكي مصر بالحق في عدم رد تكلفة تذكرة الدخول لأي ضيف غير قادر على متبعت قيود سكي مصر لل الحد الأدنى للسن والطول ومن مستويات المهارة.
                </li>
                <li>
                    عند حجز التذاكر مقدمًا من خلال خدمة عملاء سكي مصر أو عبر الإنترنت، يجب على الضيوف التحقق من الإرشادات المحددة للأنشطة الفردية لضمان امتثال المشاركين لقيود السن والطول والحد الأدنى من مستويات المهارات التي تعمل على عدد من أنشطة سكي مصر. تحتفظ سكي مصر بالحق في عدم استرداد تكلفة تذكرة الدخول إلى أي ضيف غير قادر على الامتثال لقيود السن والطول والحد الأدنى من مستويات المهارة.
                </li>
                <li>
                    يحظر التدخين في سكي مصر تمامًا.
                </li>
            </Fragment>
        )
    }
}