import React from "react";
import styles from "../home.module.scss";
import { Container, Grid } from "@material-ui/core";
import ski from "../../../img/ski.svg";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import useLocale from "../../../lang/useLocale";
import CustomSelect from "../../select/select";
import { Link } from "react-router-dom";
// import DatePicker from './DatePicker'
const Search = (props) => {
  const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();
  function handleChange(e) {
    switch (e.target.dataset.value) {
      case "snowpark":
        props.history.push(`/${lang}/prices`);
        break;
      case "skischool":
        props.history.push(`/${lang}/skischool`);
        break;
      case "penguins":
        props.history.push(`/${lang}/penguins`);
        break;
      default:
        props.history.push("/");
    }
  }
  function handleSearch(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    for (let fields of data.values()) {
      switch (fields) {
        case "snowpark":
          props.history.push(`/${lang}/prices`);
          break;
        case "skischool":
          props.history.push(`/${lang}/skischool`);
          break;
        case "penguins":
          props.history.push(`/${lang}/penguins`);
          break;
        default:
          props.history.push("/");
      }
    }
  }
  return (
    <form onSubmit={(e) => handleSearch(e)}>
      <Container maxWidth="lg" className={styles.search}>
        <Grid container className={styles.textContainer}>
          <Grid item md={8} xs={12} className={styles.text}>
            <h4>{props.data.title}</h4>
            <h2>{props.data.subtitle}</h2>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item md={10} sm={12} className={styles.bar}>
            <Grid
              container
              direction={lang === "en" ? "row" : "row-reverse"}
              justify="space-between"
              alignItems="center"
            >
              <Grid item md={8} xs={12} className={styles.searchField}>
                <img src={ski} alt="img" />
                <CustomSelect
                  changeHandler={(e) => handleChange(e)}
                  name="lang"
                  className={styles.langSelct}
                  options={[
                    { value: "snowpark", label: getLocale("snowpark") },
                    { value: "skischool", label: getLocale("SKI SCHOOL") },
                    { value: "penguins", label: getLocale("PENGUINS") },
                  ]}
                  placeholder={lang === "ar" ? "النشاط" : "Activity"}
                //   changeHandler={changeLang}
                // soon
                />
              </Grid>
              {/* <Grid item md={4} xs={12} className={styles.searchFieldFlex}>
                                <DatePicker/>
                            </Grid> */}
              <Grid item md={4} xs={12}>
                <button type="sumbit" className={styles.SearchBtn}>
                  <Link to={"/" + lang + "/aboutus"} rel="canonical">
                    {getLocale("findout")}
                  </Link>
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default withRouter(Search);
