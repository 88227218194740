import {
  ACTIVITY_LIST_FAIL,
  ACTIVITY_LIST_SUCCESS,
  ACTIVITY_LIST_REQUEST,
  LIST_OPTIONS_FAIL,
  LIST_OPTIONS_REQUEST,
  LIST_OPTIONS_SUCCESS,
  FILTERED_DATA_SUCCESS,
  FILTERED_DATA_REQUEST,
  FILTERED_DATA_FAIL,
} from "../constants/skiSchoolConstants";

export const activityListReducer = (
  state = { loading: true, data: {} },
  action
) => {
  switch (action.type) {
    case ACTIVITY_LIST_REQUEST:
      return { loading: true };
    case ACTIVITY_LIST_SUCCESS:
      return { loading: false, data: action.payload };
    case ACTIVITY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listOptionsReducer = (
  state = { loading: true, data: {} },
  action
) => {
  switch (action.type) {
    case LIST_OPTIONS_REQUEST:
      return { loading: true };
    case LIST_OPTIONS_SUCCESS:
      return { loading: false, data: action.payload };
    case LIST_OPTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const filteredDataReducer = (
  state = { loading: true, data: {} },
  action
) => {
  switch (action.type) {
    case FILTERED_DATA_REQUEST:
      return { loading: true };
    case FILTERED_DATA_SUCCESS:
      return { loading: false, data: action.payload };
    case FILTERED_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
