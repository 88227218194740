export default function usePrivateData(lang) {
	return [
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Discovery",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},

		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Discovery",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Skiing",
			age: "Adults",
			level: "Discovery",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		//Skiing Beginner Private
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Beginner",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},

		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Beginner",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Skiing",
			age: "Adults",
			level: "Beginner",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		//Skiing Level 1 Private
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Level 1",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},

		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Level 1",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Skiing",
			age: "Adults",
			level: "Level 1",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		// Skiing Level 2 Private
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Level 2",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},

		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Level 2",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Skiing",
			age: "Adults",
			level: "Level 2",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		//Skiing Level 3 Private
		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Level 3",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},

		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Level 3",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Skiing",
			age: "Adults",
			level: "Level 3",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},

		//Skiing Level 4 Private

		{
			activity: "Skiing",
			age: "3-6 Yrs",
			level: "Level 4",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},

		{
			activity: "Skiing",
			age: "7-13 Yrs",
			level: "Level 4",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Skiing",
			age: "Adults",
			level: "Level 4",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/ski-private-lesson-2-hours`,
				},
			],
		},
		//Snowboarding Discovery Private

		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Discovery",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Discovery",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},

		//Snowboarding Beginner Private

		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Beginner",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Beginner",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		//Snowboarding Level 1 Private

		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Level 1",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Level 1",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		// Snowboarding Level 2 Private

		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Level 2",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Level 2",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		// Snowboarding Level 3

		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Level 3",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Level 3",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		//Snowboarding Level 4

		{
			activity: "Snowboarding",
			age: "8-13 Yrs",
			level: "Level 4",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
		{
			activity: "Snowboarding",
			age: "Adults",
			level: "Level 4",
			privacy: "Private",
			lessons: [
				{
					time: "60 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-private-1-hour`,
				},
				{
					time: "90 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-1-5-hours`,
				},
				{
					time: "120 min",
					link: `https://booking.skiegy.com/${lang}/ski-school/booking/snowboard-private-lesson-2-hours`,
				},
			],
		},
	];
}
